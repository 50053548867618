import './home.scss';
import React, { useState, useEffect } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeLoadin from '../components/homeLoadin.js';
import HomeInfo from '../components/homeInfo.js';

const Home = () => {
  const [loadinComplete, setLoadinComplete] = useState(false);
  const handleLoadinComplete = () => {
    setLoadinComplete(true);
  };
  const [forceIntro, setForceIntro] = useState(false);

  useEffect(() => {
    checkIntroViewed();
    setForceIntro((new URLSearchParams(window.location.search)).has('intro'));
  }, [setForceIntro]);

  const checkIntroViewed = () => {
    const introViewed = sessionStorage.getItem('introViewed');
    if (introViewed) {
      setLoadinComplete(true);
    } else {
      sessionStorage.setItem('introViewed', true);
    }
  };

  const HomeContent = () => {
    return (
      <>
        {(loadinComplete && !forceIntro)  ? (
          <HomeInfo />
        ) : (
          <HomeLoadin onLoadinComplete={handleLoadinComplete} isForceIntro={forceIntro} />
        )}
      </>
    );
  };

  return (
    <Layout doHideFooter={!loadinComplete}>
      <SEO
        title="UTUC | Upper Tract Urothelial Cancer: What It Is and Your Treatment Options"
        keywords={[
          'UTUC',
          'upper tract urothelial cancer',
          'cancer of the upper urinary tract',
          'kidney cancer',
          'renal pelvis cancer',
          'transitional cell carcinoma',
          'ureteral cancer',
          'urinary tract cancer',
          'cancer in the lining of the kidney',
          'bladder tumor in the kidney',
          'bladder tumor in the ureter',
        ]}
        description="Important information about upper tract urothelial cancer (UTUC), including treatment options, questions to ask your urologist, and more. UTUC could also be called cancer of the upper urinary tract, ureteral cancer, or cancer in the lining of the kidney"
        page="home"
      />
      <div className="home__container">
        <HomeContent />
      </div>
    </Layout>
  );
};

export default Home;
