import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import questionsIcon from '../images/questions-icon.svg';
import Definition from './definition/definition';
import { Link } from 'gatsby';
import Typed from 'typed.js';

const HomeInfo = () => {
  useEffect(() => {
    initHomeInfo();
  }, []);

  let tlHomeInfo;

  const animatingTerms = [
    'Urinary tract cancer',
    'Upper tract urothelial carcinoma',
    'Renal pelvis cancer',
    'Cancer of the upper urinary tract',
    'Ureteral cancer',
    'Upper tract cancer',
    'Cancer in the lining of the kidney',
  ];

  const initHomeInfo = () => {
    tlHomeInfo = gsap.timeline();
    tlHomeInfo.to('.home__info_container', {
      opacity: 1,
      duration: 0.5,
      delay: 1,
    });
    tlHomeInfo.from('.home__options_item', {
      scale: 0.75,
      opacity: 0,
      stagger: 0.25,
      duration: 0.5,
      ease: 'back.easeOut',
    });

    document.querySelector('.term').innerText = '';

    new Typed('.term', {
      strings: animatingTerms,
      typeSpeed: 50,
      backSpeed: 8,
      backDelay: 2500,
      loop: true,
      showCursor: false,
    });
  };

  return (
    <section className="home__info_container" aria-label="home info">
      <div className="home__info_inner">
        <h2 className="home__info_title">
          Has your doctor mentioned <span>one of these?</span>
        </h2>
        <h3 className="home__info_title term">
          Upper tract urothelial carcinoma
        </h3>
        <div className="home__details_container">
          <p>
            Your <Definition term="urologist" id="urologist" /> might use any of
            these words to talk about{' '}
            <span style={{ fontWeight: 'bold' }}>
              upper tract urothelial cancer (UTUC)
            </span>
            , a type of cancer that happens in the lining of the{' '}
            <Definition term="upper urinary tract" id="upper_urinary_tract" />.
          </p>
          <p>
            Even though UTUC is rare—
            <span style={{ fontWeight: 'bold' }}>less than 1%</span> of people
            get it every year—it can have a real impact on those affected. Get
            clear on what it is, how it's diagnosed, how it's treated, and where
            to go for support.
          </p>
          <div className="home__question_container">
            <div className="icon__header">
              <img
                src={questionsIcon}
                alt="Questions Icon"
                className="icon__image"
              />
              <span className="icon__title">
                Are any of these words confusing?
              </span>
            </div>
            <p>
              If a word has a blue box behind it, just click the word to see
              what it means:{' '}
              <Definition
                term="Upper tract urothelial cancer/upper tract urothelial carcinoma (UTUC)"
                displayAs="Upper tract urothelial cancer"
                id="upper_tract_urothelial_cancer"
              />
              .
            </p>
          </div>
        </div>
      </div>
      <div className="home__options_container">
        <ul className="home__options_list">
          <li className="home__options_item">
            <Link
              to="/about-the-urinary-tract"
              className="site-header__nav_link"
              data-id="about"
            >
              <h4 className="home__options_item_title">
                What to know <span className="break-span">about UTUC</span>
              </h4>
              <p>
                Learn about the urinary tract and what types of
                <span className="break-span">cancer can happen there</span>
              </p>
            </Link>
          </li>
          <li className="home__options_item">
            <Link
              to="/treatment-options"
              className="site-header__nav_link"
              data-id="treatment"
            >
              <h4 className="home__options_item_title">
                Understand <span className="break-span">your options</span>
              </h4>
              <p>
                Learn about the treatment{' '}
                <span className="break-span">options that are available</span>
              </p>
            </Link>
          </li>
          <li className="home__options_item">
            <Link
              to="/helpful-resources"
              className="site-header__nav_link"
              data-id="doctor"
            >
              <h4 className="home__options_item_title">
                Learn from the <span className="break-span">experts</span>
              </h4>
              <p>
                Watch a video presentation by UTUC experts on{' '}
                <span className="break-span">
                  diagnosis, treatment options, and more
                </span>
              </p>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HomeInfo;
