import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const HomeLoadin = ({ onLoadinComplete, isForceIntro }) => {
  useEffect(() => {
    initHomeLoadin();
  }, []);

  let tlHomeLoadin;

  const initHomeLoadin = () => {
    tlHomeLoadin = gsap.timeline({
      onComplete: () => {
        onLoadinComplete();
      },
    });
    tlHomeLoadin.to('.home__loadin_container', {
      opacity: 1,
      duration: 0.5,
      delay: 0.3,
    });
    tlHomeLoadin.from('.home__loadin_title', {
      translateY: 10,
      opacity: 0,
      duration: 0.5,
    });
    tlHomeLoadin.from('.home__loadin_item', {
      translateY: 8,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
    });
    tlHomeLoadin.to('.home__loadin_item', {
      color: '#fff',
      stagger: {
        each: 0.1,
        yoyo: true,
      },
      duration: 0.75,
    });
    !isForceIntro &&
      tlHomeLoadin.to('.home__loadin_container', {
        opacity: 0,
        duration: 0.6,
        delay: 1,
      });

    const $mobileNav = document.querySelector('.mobile-nav__toggle_container');
    if ($mobileNav) {
      tlHomeLoadin.to(
        '.mobile-nav__toggle_container',
        { opacity: 1, duration: 0 },
        'out',
      );
    }
  };

  return (
    <section className="home__loadin_container" aria-label="home intro">
      <div className="home__loadin_content">
        <h2 className="home__loadin_title">
          Has your doctor <span>mentioned one of these?</span>
        </h2>
        <div className="home__loadin_list_container">
          <ul className="home__loadin_list">
            <li className="home__loadin_item">
              Cancer of the upper urinary tract
            </li>
            <li className="home__loadin_item">
              Upper tract urothelial carcinoma
            </li>
            <li className="home__loadin_item">Renal pelvis cancer</li>
            <li className="home__loadin_item">Ureteral cancer</li>
            <li className="home__loadin_item">Urinary tract cancer</li>
            <li className="home__loadin_item">Upper tract cancer</li>
            <li className="home__loadin_item">
              Cancer in the lining of the kidney
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HomeLoadin;
